/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {faBrowser} from '@fortawesome/pro-solid-svg-icons';
import {faExternalLinkAlt} from '@fortawesome/pro-regular-svg-icons';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p"
  }, _provideComponents(), props.components), {FontAwesomeIcon} = _components;
  if (!FontAwesomeIcon) _missingMdxReference("FontAwesomeIcon", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Local Transport"), "\n", React.createElement("h3", null, "Buses"), "\n", React.createElement("p", null, React.createElement(_components.p, null, "Hornsby Travel: Bus number 60 which runs"), React.createElement("br"), React.createElement("ul", {
    className: "list-none"
  }, React.createElement("li", null, "Monday to Friday 10:55 to 18:43 (6 journeys)"), "\n", React.createElement("li", null, "Saturdays, 12:15 to 18:38 (5 journeys)"))), "\n", React.createElement("p", null, React.createElement("a", {
    title: "Hornsby bus Route 60",
    href: "https://bustimes.org/services/60-scunthorpe-flixborough-burton-whitton-3",
    target: "_blank",
    rel: "noopener"
  }, React.createElement(_components.p, null, React.createElement(FontAwesomeIcon, {
    icon: faBrowser,
    size: "sm"
  }), " : Click here for full timetable ", React.createElement(FontAwesomeIcon, {
    icon: faExternalLinkAlt,
    size: "xs"
  })))), "\n", React.createElement("hr"), "\n", React.createElement("h3", null, "Trains"), "\n", "\n", "\n", React.createElement("p", null, "Nearest Station: Scunthorpe Railway Station, Station Road, Scunthorpe, DN15 6PY"), "\n", React.createElement("p", null, React.createElement(_components.p, null, "For more information and upto date train times please visit the", ' '), React.createElement("a", {
    title: "Scunthorpe station",
    href: "http://www.nationalrail.co.uk/stations/scu/details.html",
    target: "_blank",
    rel: "noopener"
  }, React.createElement(_components.p, null, React.createElement(FontAwesomeIcon, {
    icon: faBrowser,
    size: "sm"
  }), " : National Rail Enquiries website ", React.createElement(FontAwesomeIcon, {
    icon: faExternalLinkAlt,
    size: "xs"
  })))), "\n", React.createElement("p", null, React.createElement(_components.p, null, "Trainline, Europe’s leading train and coach app. They work with 270 rail and coach companies to offer customers travel\nto thousands of destinations in and across 45 countries in Europe.", ' '), React.createElement("a", {
    title: "Link to Trainline",
    href: "https://www.thetrainline.com/",
    target: "_blank",
    rel: "noopener"
  }, React.createElement(_components.p, null, React.createElement(FontAwesomeIcon, {
    icon: faBrowser,
    size: "sm"
  }), " : Trainline.com ", React.createElement(FontAwesomeIcon, {
    icon: faExternalLinkAlt,
    size: "xs"
  })))), "\n", React.createElement("hr"), "\n", React.createElement("h3", null, "Taxis"), "\n", React.createElement("p", null, React.createElement(_components.p, null, "The list in at attached link at Thompsons may not be complete, but should provide for a good start to local taxi\nfirms."), React.createElement("br"), React.createElement("a", {
    href: "https://www.thomsonlocal.com/Taxis/in/Burton-upon-Stather-South-Humberside/"
  }, React.createElement(_components.p, null, React.createElement(FontAwesomeIcon, {
    icon: faBrowser,
    size: "sm"
  }), " : Thompsons Local ", React.createElement(FontAwesomeIcon, {
    icon: faExternalLinkAlt,
    size: "xs"
  })))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
